import { defineStore } from 'pinia'
import {type GetOwnedProperty, RightOfUseApi, UserApi} from "~/gen/openapi/portalService";
import {notNil} from "~/helpers/filters";
import {extractErrorMessagesFromErrorOrResponse} from "~/helpers/errors";
import {useAccountStore} from "~/stores/account";
import {ROLE_FOREST_OWNER} from "~/store/constants/customer";
import {detectUserScenario} from "~/helpers/delegationOfAuthority";
import {useAccountService} from "~/services/account";

/**
 * Describes what kind of scenario the current user is in.
 */
export const ScenarioEnum = Object.freeze({
    /**
     * 1:1, aka "Happy Case" – User is the sole owner of the property, and owner of the ENK.
     */
    ONE_TO_ONE: '1:1',
    /**
     * forest-owner – User is the owner of the property, but not the owner of the ENK.
     */
    FOREST_OWNER: 'forest-owner',
    /**
     * company-owner – User is the owner of the ENK, but someone else owns the property.
     */
    COMPANY_OWNER: 'company-owner',
    /**
     * not-real-owner – User is neither owner of the property nor the ENK, but still has access to the account for
     * historical reasons. Common use case is that logged in person is a family member, and is taking care of digital
     * matters for the owner.
     */
    NOT_REAL_OWNER: 'not-real-owner',
})

export type ScenarioType = typeof ScenarioEnum[keyof typeof ScenarioEnum]

interface State {
    ownedPropertiesContact: Array<GetOwnedProperty>,
    ownedPropertiesAccount: Array<GetOwnedProperty>,
    propertiesWithRightOfUseAccount: Array<GetOwnedProperty>,
    currentAccountId: string | null,
    fetchErrors: {[key: string]: Array<string>},
    loading: {[key: string]: boolean},
    /**
     * Whichever scenario the current account is in after verifying with bankId.
     * */
    currentAccountScenario: ScenarioType | undefined,
    requestAccountPropFetching: boolean | undefined,
    requestOwnedPropFetching: boolean | undefined,
}

/**
 * Shared store for everything related to forest owner properties and access/delegation
 */
export const useDelegationOfAuthorityStore = defineStore('delegationOfAuthority', {
  state: (): State => ({
      ownedPropertiesContact: [],
      ownedPropertiesAccount: [],
      propertiesWithRightOfUseAccount: [],
      currentAccountId: null,
      fetchErrors: {},
      loading: {},
      currentAccountScenario: undefined,
      requestAccountPropFetching: undefined,
      requestOwnedPropFetching: undefined,
	}),
	getters: {
        isLoading(state) {
            return Object.values(state.loading).includes(true)
        },
        errors(state) {
            return Object.values(state.fetchErrors).flat().filter(notNil);
        }
	},
	actions: {
      /* ### MUTATIONS ### */
      // included for easier migration

        setCurrentAccountId(accountId: string) {
            this.currentAccountId = accountId;
        },

        setOwnedPropertiesContact(properties: Array<GetOwnedProperty>) {
            this.ownedPropertiesContact = properties;
        },

        setOwnedPropertiesAccount(properties: Array<GetOwnedProperty>) {
            this.ownedPropertiesAccount = properties;
        },

        setPropertiesWithRightOfUseAccount(properties: Array<GetOwnedProperty>) {
            this.propertiesWithRightOfUseAccount = properties;
        },

        setContactPropertiesFetchErrors(errors: Array<string>) {
            this.fetchErrors = {
                ...this.fetchErrors,
                contactProperties: errors
            }
        },

        setAccountPropertiesFetchErrors(errors: Array<string>) {
            this.fetchErrors = {
                ...this.fetchErrors,
                accountProperties: errors
            }
        },

        setLoadingContactProperties(loading: boolean) {
            this.loading = {
                ...this.loading,
                contactProperties: loading
            }
        },

        setLoadingAccountProperties(loading: boolean) {
            this.loading = {
                ...this.loading,
                accountProperties: loading
            }
        },

        setIsDetectingAccountScenario(loading: boolean) {
            this.loading = {
                ...this.loading,
                detectingAccountScenario: loading
            }
        },

        setCurrentAccountScenario(scenario: ScenarioType) {
            this.currentAccountScenario = scenario;
        },

        /* ### ACTIONS ### */

        async loadOwnedPropertiesContact() {
            if (this.requestOwnedPropFetching) {
                return
            }
            this.requestOwnedPropFetching = true
            this.setContactPropertiesFetchErrors([])
            const {$axios, $config} = useNuxtApp()
            try {
                this.setLoadingContactProperties(true)
                const api = new RightOfUseApi(undefined, $config.public.apiBaseHost, $axios);
                const response = await api.rightOfUseGetOwnedPropertiesContact();
                this.setOwnedPropertiesContact(response.data.Properties as GetOwnedProperty[])
            } catch (e) {
                this.setOwnedPropertiesContact([])
                this.setContactPropertiesFetchErrors(extractErrorMessagesFromErrorOrResponse(e))
            } finally {
                this.setLoadingContactProperties(false)
                this.requestOwnedPropFetching = false;
            }
        },

        async loadPropertiesForAccount(accountId: string): Promise<void> {
            const accountStore = useAccountStore()
            if (this.requestAccountPropFetching) {
                return
            }
            this.requestAccountPropFetching = true
            this.setAccountPropertiesFetchErrors([])
            this.setCurrentAccountId(accountId)
            const {$axios, $config} = useNuxtApp()

            try {
                this.setLoadingAccountProperties(true)
                const api = new RightOfUseApi(undefined, $config.public.apiBaseHost, $axios);
                const userApi = new UserApi(undefined, $config.public.apiBaseHost, $axios);
                const ContactIdCrm = (await userApi.userGetLoggedInUser()).data
                const responses = await Promise.all([
                    api.rightOfUseGetOwnedPropertiesAccount({
                        AccountIdCrm: accountId,
                        Name: accountStore.account.Name,
                        OrgNumber: accountStore.account.OrganisationNumber,
                        ContactIdCrm
                    }),
                    api.rightOfUseGetPropertiesWithRightOfUseAccount({
                        AccountIdCrm: accountId,
                        Name: accountStore.account.Name,
                        OrgNumber: accountStore.account.OrganisationNumber,
                        ContactIdCrm
                    })
                ])

                this.setOwnedPropertiesAccount(responses[0].data.Properties as GetOwnedProperty[])
                this.setPropertiesWithRightOfUseAccount(responses[1].data.Properties  as GetOwnedProperty[])
            } catch (e) {
                this.setOwnedPropertiesAccount([]);
                this.setPropertiesWithRightOfUseAccount([]);
                this.setAccountPropertiesFetchErrors(extractErrorMessagesFromErrorOrResponse(e));
            } finally {
                this.setLoadingAccountProperties(false)
                this.requestAccountPropFetching = false
            }
        },

        reset() {
            this.setOwnedPropertiesContact([])
            this.setOwnedPropertiesAccount([])
            this.setPropertiesWithRightOfUseAccount([])
            this.currentAccountId = null
        },

        /**
         * Detects the scenario the current account is in after verifying with bankId.
         */
        async detectCurrentUserAndAccountScenario(accountId: string): Promise<void> {
            const {$axios, $config} = useNuxtApp()
            try {
                this.setIsDetectingAccountScenario(true)
                const userApi = new UserApi(undefined, $config.public.apiBaseHost, $axios);
                const ContactIdCrm = (await userApi.userGetLoggedInUser()).data
                // Step 0 – Load all needed data
                await Promise.all([
                    // this.loadPropertiesForAccount(accountId),
                    this.loadOwnedPropertiesContact()
                ])

                this.setCurrentAccountScenario(
                // @ts-ignore
                    detectUserScenario({
                        ownedPropertyContact: this.ownedPropertiesContact?.[0],
                        ownedPropertyAccount: this.ownedPropertiesAccount?.[0],
                        propertyWithRightOfUse: this.propertiesWithRightOfUseAccount?.[0],
                        contactId: ContactIdCrm
                    })
                )
            } catch (e) {
                console.error(e)
            } finally {
                this.setIsDetectingAccountScenario(false)
            }
        }
	}
})
