import type {GetOwnedProperty} from '~/gen/openapi/portalService';
import type {ScenarioType} from '~/stores/delegationOfAuthority';
import {ScenarioEnum} from "~/stores/delegationOfAuthority";

interface ScenarioCheckOptions {
	accountId: string;
	ownedPropertyContact?: GetOwnedProperty;
	ownedPropertyAccount?: GetOwnedProperty;
	propertyWithRightOfUse?: GetOwnedProperty;
	contactId?: string;
}

/**
 * Detects the scenario the current user is in (context: delegation of authority).
 * See {@link ScenarioEnum} for details.
 */
export function detectUserScenario({
																		 ownedPropertyAccount,
																		 ownedPropertyContact,
																		 propertyWithRightOfUse,
																		 contactId,
																	 }: ScenarioCheckOptions): ScenarioType | undefined {

	if (propertyWithRightOfUse?.AccountWithRightOfUseIsEnk && propertyWithRightOfUse?.OwnerOfAccountWithRightOfUseAndForestOwnerIsSame) {
		// happy case, 1:1
		if (propertyWithRightOfUse.ContactIsOwnerAllma) {
			return ScenarioEnum.ONE_TO_ONE
		} else if (!propertyWithRightOfUse.PowersOfAttorney?.some(poa => poa.PowerOfAttorney?.ContactIdCrm === contactId)) {
			// special scenario: Happy case, but the logged-in user is not the owner of property nor company.
			// Only valid scenario if user has not already received a POA.
			return ScenarioEnum.NOT_REAL_OWNER
			}
	}

	if (ownedPropertyContact) {
		// Properties exists for the logged-in user.
		// Edge-case: a user might need to re-verify themselves, and right of use is already set up on the property.
		const rightOfUseAlreadyAssignedToAccountId = ownedPropertyContact?.AccountsWithRightOfUse?.[0]?.AccountWithRightOfUse?.AccountIdCrm
		if (rightOfUseAlreadyAssignedToAccountId) {
			return ScenarioEnum.ONE_TO_ONE
		}
		return ScenarioEnum.FOREST_OWNER
	}

	if (ownedPropertyAccount) {
		return ScenarioEnum.COMPANY_OWNER
	}

	return undefined
}

export function convertErrorArrayToSsnError(arr: Array<{ Id: string, Error: string }>) {
	return arr.reduce<{ [key: string]: string }>((prev, current) => {
		let err;
		if (current.Error === 'Personal information cannot be found in Folkeregisteret service') {
			err = 'poa.error.NOT_A_MATCH_IN_FOLKEREGISTERET'
		} else if (current.Error === 'The email belongs to other contact') {
			// ignore, not related to SSN
			return prev
		} else {
			err = 'SOMETHING_WENT_WRONG'
		}
		return {...prev, [current.Id]: err}
	}, {})
}

export function convertErrorArrayToEmailError(arr: Array<{ Id: string, Error: string }>) {
	return arr.reduce((acc, v) => {
		let err = ''
		if (v.Error === 'The email belongs to other contact') {
			err = 'EMAIL_ALREADY_IN_USE'
		}
		if (err) {
			return {...acc, [v.Id]: err}
		}
		return acc
	}, {})
}
